import AdyenCheckout from '@adyen-web-3.23.0';
import '@adyen-web-3.23.0/dist/adyen.css';

import Vue from 'vue';

import get from 'lodash/get';

import { ENV } from '@emobg/web-utils';
import config from '@/config';
import { ADYEN_ENVIRONMENT_CONFIG, DEFAULT_IFRAME_SIZE, IFRAME_ALL_WIDTH } from '../constants/adyen.const';

import AdyenStoreHubNameSpace from '../stores/adyenStore';

import {
  authenticateChallenge,
  authenticateCompanyRedirect,
  authenticateRedirect,
  confirmRedirect,
  redirectBookingPaymentResult,
  redirectResult,
  storePaymentMethodByUUID,
} from '../api/adyenApi';

const originKey = get(AdyenStoreHubNameSpace, 'state.originKey');
export const adyenEnvironment = () => (
  config.data.environment === ENV.production ? ADYEN_ENVIRONMENT_CONFIG.live : ADYEN_ENVIRONMENT_CONFIG.test
);

export const redirectQuery = '3ds1createpaymentmethod';
export const redirectConfirmQuery = '3ds1confirm';

export const providerHelperMountPoint = 'provider-helper';
export const providerHelperComponent = Vue.component('ProviderHelperComponent', {
  render(createElement) {
    return createElement('div', { attrs: { id: providerHelperMountPoint } });
  },
});

export const createFromAction = (configuration, response) => {
  const actionConfiguration = {
    ...configuration,
    environment: adyenEnvironment(),
    clientKey: originKey,
  };

  const checkout = new AdyenCheckout(actionConfiguration);
  const parsedAction = JSON.parse(response.action);

  checkout.createFromAction(parsedAction).mount(`#${providerHelperMountPoint}`);
};

export const storeRedirectPaymentMethod = async (paymentMethodData, customerData) => {
  const { paymentMethodUuid } = paymentMethodData;

  const storedPaymentInfo = await storePaymentMethodByUUID(customerData.profileType, paymentMethodUuid);

  const commonConfig = {
    reference: paymentMethodData.reference,
    paymentData: paymentMethodData.paymentData,
    session: storedPaymentInfo.md,
    type: paymentMethodData.type,
  };

  if (customerData.isBusinessInCompanyPays) {
    const authConfig = {
      ...commonConfig,
      companyUuid: customerData.companyUuid,
      requestData: storedPaymentInfo.paRes,
    };

    return authenticateCompanyRedirect(authConfig, customerData.companyUuid, paymentMethodUuid);
  }

  const augmentedAuthConfig = {
    ...commonConfig,
    userUuid: customerData.userUuid,
    paResponse: storedPaymentInfo.paRes,
  };

  return authenticateRedirect(augmentedAuthConfig);
};

const getGeneratedToken = details => {
  const fingerprint = get(details, 'threeds2.fingerprint');
  const challenge = get(details, 'threeds2.challengeResult');

  return fingerprint || challenge;
};

export const onAdditionalDetails = async (configuration, response, callbackFn, isIframeAllWidth) => {
  const actionConfiguration = {
    ...configuration,
    environment: adyenEnvironment(),
    clientKey: originKey,
    onAdditionalDetails: callbackFn,
  };

  const parsedAction = JSON.parse(response.action);

  const checkout = new AdyenCheckout(actionConfiguration);

  const threeDSConfiguration = {
    challengeWindowSize: isIframeAllWidth ? IFRAME_ALL_WIDTH : DEFAULT_IFRAME_SIZE,
  };

  checkout.createFromAction(parsedAction, threeDSConfiguration).mount(`#${providerHelperMountPoint}`);
};

export const handleOnAdditionalDetails = (state, _component, response, customerData, version) => {
  const authConfig = {
    reference: response.reference,
    paymentData: response.paymentData,
    verificationStep: response.providerStatus,

    details: state.data.details,
    generatedToken: getGeneratedToken(state.data.details),

    type: customerData.type,
    userUuid: customerData.isBusinessInCompanyPays ? null : customerData.userUuid,
    companyUuid: customerData.isBusinessInCompanyPays ? customerData.companyUuid : null,
  };

  return authenticateChallenge(authConfig, version);
};

export const getRedirectResult = reference => redirectResult(reference);

export const postConfirmRedirect = redirectResponse => confirmRedirect(redirectResponse);

export const getBookingPaymentResult = bookingInvoiceUuid => redirectBookingPaymentResult(bookingInvoiceUuid);
